import React from "react";
import "./walletpage.css";
import Money from "../../assets/icon/money.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Icon } from "@iconify/react";
import Navbar from "../../components/home/Navbar/Navbar";

const walletpage = () => {
  return (
    <>
      <Navbar />
      <div className="wallet-section">
        <div className="wallet-topbar">
          <div className="wallet-title">
            <h5>Road to 1st Cash Out</h5>
          </div>
          <div className="subheading">
            <p>
              To prevent cheating & spam, you must meet the following
              requirements for your 1st cash out.
            </p>
          </div>
          <div className="balance-percentage">
            <div className="percentage-left">
              <img src={Money} alt="money" />
            </div>
            <div className="percentage-right">
              <span>Balance 40%</span>
              <ProgressBar now={25} className="progressbr" />
            </div>
          </div>
          <div className="topbar-bottom">
            <div className="title">
              <p>
                The minimum amount for the first withdrawal is $500, and there
                is no amount limit for subsequent withdrawals.
              </p>
              <button>EARN MONEY NOW</button>
            </div>
          </div>
        </div>
        <div className="walletlast-content">
          <div className="status-title">
            <button>Status : Not Payable</button>
          </div>
          <div className="payment-detail">
            <label htmlFor="" className="balencelabel">
              Your Balance :
            </label>
            <input
              type="text"
              placeholder="Current Balance $400"
              className=" balencefield"
              disabled
            />
          </div>
          <div className="payment-detail">
            <label htmlFor="" className="balencelabel">
              Payment Method{" "}
            </label>

            <select
              className="form-select balencefielddropd"
              aria-label="Default select example"
            >
              <option selected className="balencefielddropd">
                Select Your Payment Method
              </option>
              <option value="1" className="balencefielddropd">
                One
              </option>
              <option value="2" className="balencefielddropd">
                Two
              </option>
              <option value="3" className="balencefielddropd">
                Three
              </option>
            </select>
            <button>
              {" "}
              <Icon icon="akar-icons:link-out" width="20" height="20" />
              Click Here To Set Payment Method
            </button>
            <button className="cash-out">Cash Out</button>
            <label htmlFor="" className="balencelabel">
              Withdrawal History
            </label>

            <select
              className="form-select balencefielddropd"
              aria-label="Default select example"
            >
              <option selected className="balencefielddropd">
                You Have No Withdrawal Record
              </option>
              <option value="1" className="balencefielddropd">
                One
              </option>
              <option value="2" className="balencefielddropd">
                Two
              </option>
              <option value="3" className="balencefielddropd">
                Three
              </option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default walletpage;
