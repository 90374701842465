import React, { useEffect, useState } from "react";
import Cacard from "./Cacard";
// import Catagerycard from "./catagerycard";
import Card from "../card";
import { useNavigate } from "react-router-dom";

const EntertainmentQuiz = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const [currentLocation, setCurrentLocation] = useState();
  // console.log(location.pathname);
  const handleButtonClick = () => {
    navigate("/quizez");
  };
  // useEffect(() => {
  //   console.log("hsah");
  //   setCurrentLocation(location.pathname);
  // }, [location]);
  return (
    <div>
      <div className="quiz-grid">
        {/* <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card /> */}
        <Card title={'Hollywood'} image="../../../asset/images/image38.png" />
        <Card title={'Bollywood'} image="../../../asset/images/image37.png" />
        {/* <Cacard />
        <Cacard />
        <Cacard />
        <Cacard /> */}
      </div>
      {/* {currentLocation == "/quizez" ? (
        <div className="button-show">
          <button
            className="showmore-btn"
            onClick={() => console.log("Load more button clicked")}
          >
            Load more
          </button>
        </div>
      ) : (
        <div className="button-show">
          <button className="showmore-btn" onClick={handleButtonClick}>
            Show more
          </button>
        </div>
      )} */}
    </div>
  );
};

export default EntertainmentQuiz;
