import "./Modal.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import MImg from "../assets/icon/Rectangle 54.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Mod = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Navigate to the About page
    navigate("/quizpanel");
  };
  return (
    <>
      <>
        {/* <Button variant="primary" onClick={() => setShow(true)}>
          Custom Width Modal
        </Button> */}

        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          fullscreen={false}
          size="xl"
          centered
          className="modalbox-Main xl"
        >
          {/* <Modal.Header closeButton className="modal-Content">
            <Modal.Title id="example-custom-modal-styling-title">
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Body className="modal-Content">
            <div className="quizModal-section">
              <div className="qmodal-left">
                <div className="modalImg">
                  <img src={MImg} alt="MImg" className="modalimg" />
                </div>
              </div>
              <div className="qmodal-right">
                <div className="quiz-section">
                  <div className="question">
                    <h6>Question</h6>
                    <div className="paragraph">
                      <p>What's the first thing Hannah says on tape one?</p>
                    </div>{" "}
                  </div>
                  <div className="modalReward">
                    <p>
                      Reward<span>$10</span>
                    </p>
                  </div>
                  <div className="quiz-start">
                    <div className="start-div">
                      <p>Do You Want To Start The Quiz ? Tap On The Button!</p>
                    </div>
                    {/* <Link to={"/quizpanel"}> */}
                    <button>Let’s Start</button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default Mod;
