import React from "react";
import Banner from "../components/home/banner";
import Vision from "../components/home/vision";
import Quizzes from "../components/quizzes/quizzes";
import Features from "../components/home/features";
import Faq from "../components/home/faq/faq";
import Footer from "../components/footer/footer.";
import Navbar from "../components/home/Navbar/Navbar";
const Home = () => {
  return (
    <div>
      <Navbar />
      <Banner />
      <Vision />
      <Quizzes />
      <Features />
      <Faq head="FAQ" />
      <Footer />
    </div>
  );
};

export default Home;
