import React, { useCallback, useEffect, useState } from "react";
import ".././style/main.scss";
import football from "../../src/assets/images/Homepage-card-images/football.png";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import Mod from "../Modal/Modal";
import Modal from "react-bootstrap/Modal";
import "../Modal/Modal.css";
import MImg from "../assets/icon/Rectangle 54.png";


import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
// import Web3 from "web3";
// import { useAccount, useBalance } from "wagmi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Card = ({ title, image }) => {
  const [show, setShow] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  // const [selectedCategory, setSelectedCategory] = useState('');
  // const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [questions, setQuestions] = useState([])
  const [questions1, setQuestions1] = useState("")

  const letsStart = () => { };
  // let title = ""
  // const web3 = new Web3(window.ethereum);
  const navigate = useNavigate();
  // const { address } = useAccount()

  // const { data, isError, isLoading } = useBalance({
  //   address: address,
  //   chainId: 11155111
  // })
  // console.log(data)

  const handleButtonClick = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/Movies/questions`)
      .then((res) => {
        // console.log("Response from server:", res.data);
        setQuestions1(res.data)

        navigate("/quizpanel", {
          state: {
            data: res.data.ans.questions.questions
          }
        });
      })
      .catch((err) => {
        console.log("Error fetching questions:", err);
      });

  }, [setQuestions1, navigate]);

  const handleStartQuiz = async () => {
    setShow(true)
    setLoadingQuestions(true);

    // console.log("props", title);
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/Movies/startquiz`, {
        mainCategory: 'Movies',
        subCategory: title,
      })
      .then((response) => {
        console.log("response", response.data);
        if (response.data && response.data.questions) {
          setQuestions(response.data.questions);
        } else {
          console.error('Unexpected API response:', response);
        }
        setLoadingQuestions(false);
      })
      .catch((error) => {
        console.error('Error fetching questions from startquiz:', error);
        setLoadingQuestions(false);
      });
  };

  // const { title } = props;
  let content;

  if (title === 'Hollywood') {
    content = 'Which actor portrayed the iconic character Jack Dawson in the 1997 blockbuster film "Titanic"?';
  } else if (title === 'Bollywood') {
    content = 'Who is often referred to as the "King of Bollywood" and has appeared in over 200 Indian films?';
  } else {
    content = 'Unknown card type.';
  }




  return (
    <>
      <div>
        <div className="quizzes-card" onClick={() => {

          // if (data?.formatted > 0.5) {
          handleStartQuiz()
          // } else {
          //   toast("Your Balance Is Low");
          // }
        }}>
          {/* Bollywood */}
          <div className="card-header">
            {/* {console.log("image", imagess)} */}
            <img src={image} alt="card-img" className="img-100" />
          </div>
          <div className="card-body mt-4 pt-2">
            <h6>
              {/* {title} */}
            </h6>
            <p>
              {content}
            </p>
          </div>
          <div className="card-footer">
            <h6 className="v-center h-center text-yellow ">$10</h6>
            <div className="banner-btn">
              {/* {data ?  */}
              {/* ( */}
              <Button
                // text="Hollywood"
                text={title}
                width="92px"
                height="28px"
                className="btn-prime btn-primary "

              />
              {/* ) : <p> please Connect to wallet</p>} */}

            </div>
          </div>
        </div>
      </div>


      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w loading-question"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        fullscreen={false}
        size={loadingQuestions ? "lg" : "xl"}
        centered
        className="modalbox-Main xl"
      >
        <Modal.Body className="modal-Content">


          {loadingQuestions ? (

            <div className="loading-container">
              <div className="loading-content">
                <h5>AI is Generating Quiz...</h5>
                <p>This may take while</p>
                <div className="custom-loader"></div>
                <button className="close-button" onClick={() => { setShow(false) }}>Close</button>
              </div>
            </div>
          ) : (
            <div className="quizModal-section">
              <div className="qmodal-left">
                <div className="modalImg">
                  <img src={MImg} alt="MImg" className="modalimg" />
                </div>
              </div>
              <div className="qmodal-right">
                <div className="quiz-section">
                  <div className="question">
                    <h6>Question</h6>
                    <div className="paragraph">
                      <p>What's the first thing Hannah says on tape one?</p>
                    </div>{" "}
                  </div>
                  <div className="modalReward">
                    <p>
                      Reward<span>$10</span>
                    </p>
                  </div>
                  <div className="quiz-start">
                    <div className="start-div">
                      <p>Do You Want To Start The Quiz ? Tap On The Button!</p>
                    </div>
                    <button onClick={handleButtonClick}>Let’s Start</button>
                  </div>
                </div>
              </div>
            </div>
          )}

        </Modal.Body>
      </Modal >

    </>
  );
};

export default Card;
