import React from "react";
import "./LeaderBpage.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import Navbar from "../../components/home/Navbar/Navbar";

const LeaderBpage = () => {
  return (
    <>
      <Navbar />
      <div className="leadersboard-section">
        <div className="leaderBoard-title">
          <h4>leaders board</h4>
        </div>
        <div className="board-table">
          <div className="table-contentmain">
            <div className="table-content">
              <div className="table-title">
                <h5>Community Progress</h5>
              </div>
              <div className="table">
                {/* <div className="table-body">
                  <div className="tabel-serial">No</div>
                  <div className="tabel-name">Names</div>
                  <div className="tabel-progress">Progress</div>
                  <div className="tabel-quizzes">Quizzes</div>
                </div>
                <div className="table-body">
                  <div className="tabel-serial">1</div>
                  <div className="tabel-name">
                    {" "}
                    <span>Charlotte</span> <br />
                    <span className="shar-lit">shar-lit</span>{" "}
                  </div>
                  <div className="tabel-progress ">
                    <div className="pr">
                      <ProgressBar now={25} className="progressbr" />{" "}
                      <span>100%</span>
                    </div>
                  </div>
                  <div className="tabel-quizzes">100</div>
                </div> */}
                <Table className="table-structure">
                  <thead>
                    <tr>
                      <th className="serial-no">No</th>
                      <th className="tableName"> Name</th>
                      <th className="progs">Progress</th>
                      <th className="quizez">Quizzes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="serialNo">1</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={90} className="progressbr" />{" "}
                          <span>100%</span>
                        </div>
                      </td>
                      <td className="marks">100</td>
                    </tr>
                    <tr>
                      <td className="serialNo">2</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={85} className="progressbr" />{" "}
                          <span>85%</span>
                        </div>
                      </td>
                      <td className="marks">50</td>
                    </tr>
                    <tr>
                      <td className="serialNo">3</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={80} className="progressbr" />{" "}
                          <span>80%</span>
                        </div>
                      </td>
                      <td className="marks">80</td>
                    </tr>
                    <tr>
                      <td className="serialNo">4</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={75} className="progressbr" />{" "}
                          <span>100%</span>
                        </div>
                      </td>
                      <td className="marks">100</td>
                    </tr>
                    <tr>
                      <td className="serialNo">5</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={70} className="progressbr" />{" "}
                          <span>85%</span>
                        </div>
                      </td>
                      <td className="marks">50</td>
                    </tr>
                    <tr>
                      <td className="serialNo">6</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={65} className="progressbr" />{" "}
                          <span>80%</span>
                        </div>
                      </td>
                      <td className="marks">80</td>
                    </tr>
                    <tr>
                      <td className="serialNo">7</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={60} className="progressbr" />{" "}
                          <span>100%</span>
                        </div>
                      </td>
                      <td className="marks">100</td>
                    </tr>
                    <tr>
                      <td className="serialNo">8</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={55} className="progressbr" />{" "}
                          <span>85%</span>
                        </div>
                      </td>
                      <td className="marks">50</td>
                    </tr>
                    <tr>
                      <td className="serialNo">9</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={53} className="progressbr" />{" "}
                          <span>80%</span>
                        </div>
                      </td>
                      <td className="marks">80</td>
                    </tr>
                    <tr>
                      <td className="serialNo">10</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={50} className="progressbr" />{" "}
                          <span>80%</span>
                        </div>
                      </td>
                      <td className="marks">80</td>
                    </tr>
                    <tr>
                      <td className="serialNo">11</td>
                      <td className="nameborder">
                        {" "}
                        <span className="firstName">Charlotte</span> <br />
                        <span className="secondName">shar-lit</span>
                      </td>
                      <td className="nameborder">
                        {" "}
                        <div className="pr">
                          <ProgressBar now={45} className="progressbr" />{" "}
                          <span>80%</span>
                        </div>
                      </td>
                      <td className="marks">80</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaderBpage;
