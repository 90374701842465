/* eslint-disable no-unused-vars */

// import React, { useEffect, useState } from "react";
// import Logo from "../../../assets/icon/quizbuzzlogo.png";
// import "./Navbar.css";
// import { Icon } from "@iconify/react";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
// import { createConfig, useAccount, useBalance, useConnect, useDisconnect } from "wagmi";
// import { InjectedConnector } from 'wagmi/providers/injected'
// import { WagmiConfig, createConfig, configureChains, mainnet } from "wagmi";
// const Navbar = () => {
//   const navigate = useNavigate();
//   const { address } = useAccount()
//   const { connect, isConnecting } = useConnect({
//     connector: new InjectedConnector(),
//   })

//   const { data, isError, isLoading } = useBalance({
//     address: address,
//     chainId: 11155111
//   })
//   console.log(data)
//   // if (isLoading) return Fetching balance…</div>
//   // if (isError) return <div>Error fetching balance</div>

//   const config = createConfig({
//     autoConnect: true,
//     publicClient,
//     connectors: [
//       new InjectedConnector({
//         chains,
//         options: {
//           name: 'Injected',
//           shimDisconnected: true,
//         },
//       })
//     ]
//   })

//   if (address) {
//     return (
//       <>
//         <p>Connected to {address}</p>
//         <div>Balance: {data?.formatted} {data?.symbol}</div>
//         <p>Chain ID: {config ? config.lastUsedChainId : ""}</p>
//         <button onClick={useDisconnect}>Disconnect</button>
//       </>
//     )
//   }
//   // if (isLoading) { return ("Fetching balance…") }
//   // else { return ("Error fetching balance") }

//   const handleButtonClick = () => {
//     navigate("/wallet");

//   };
//   return (
//     <>
//       {/* <div>
//         Balance: {data?.formatted} {data?.symbol}
//       </div> */}
//       <nav className="navbar navbar-expand-lg navbar-main ">
//         <div className="container-fluid">
//           <Link className="navbar-brand" to="/">
//             <img src={Logo} alt="logo" className="logo" />;
//           </Link>
//           <button
//             className="navbar-toggler toggleButton"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarSupportedContent"
//             aria-controls="navbarSupportedContent"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//           >
//             <span className="navbar-toggler-icon">
//               <Icon
//                 icon="ci:hamburger-lg"
//                 color="white"
//                 width="30"
//                 height="30"
//               />
//             </span>
//           </button>
//           <div
//             className="collapse navbar-collapse mainMiddle"
//             id="navbarSupportedContent"
//           >
//             <ul className="navbar-nav  navUL">
//               <li className="nav-item">
//                 <Link className="nav-link" aria-current="page" to="/">
//                   Home
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link " aria-current="page" href="#">
//                   About us
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link " aria-current="page" to="/quizez">
//                   Quizzes
//                 </Link>
//               </li>
//             </ul>
//             <div className="walletButton">
//               {/* <form className="d-flex" role="search"> */}
//               <ConnectButton />
//               {/* <button className="wallet" onClick={handleButtonClick}>
//                 Wallet
//               </button> */}
//               {/* </form> */}

//             </div>
//           </div>
//         </div>
//       </nav>
//     </>
//   );
// };

// export default Navbar;

import Card from "./components/card";
import Navbar from "./components/home/Navbar/Navbar";
import Moviepage from "./pages/Moviepage/Moviepage";
import Quiz from "./pages/Quiz/Quiz";
import Quizpanel from "./pages/Quizpanel/Quizpanel";
import Home from "./pages/home";
import "./style/main.scss";
import Viking from "./assets/icon/viking.png";
import Walletpage from "./pages/walletpage/walletpage";
import { useState } from "react";
import LeaderBpage from "./pages/LeaderBpage/LeaderBpage";
import Modalpage from "./pages/Modalpage/Modalpage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Mod from "./Modal/Modal";
import Answermodal from "./Modal/Answermodal";
import Answermodal2 from "./Modal/Answermodal2";
import Answermodal3 from "./Modal/Answermodal3";
import Quizpanel2 from "./pages/Quizpanel/Quizpanel2";
import Quizpanel3 from "./pages/Quizpanel/Quizpanel3";
import Modal2 from "./Modal/Modal2";
import Modal3 from "./Modal/Modal3";
import Modal4 from "./Modal/Modal4";
import Firstmodal from "./Modal/Firstmodal";
// import '@rainbow-me/rainbowkit/styles.css';
// import {
//   getDefaultWallets,
//   RainbowKitProvider,
// } from '@rainbow-me/rainbowkit';
// import { configureChains, createConfig, WagmiConfig } from 'wagmi';
// import { sepolia } from "viem/chains"
// import { publicProvider } from 'wagmi/providers/public';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  // const [movieImg, setmovieImg] = useState();

  // const flag = 1;

  // if (flag === 1) {
  //   setmovieImg(Viking);
  // }
  // if (flag === 2) {
  //   setmovieImg(Viking);
  // }
  // if (flag === 3) {
  //   setmovieImg(Viking);
  // }

  // const { chains, publicClient } = configureChains(
  //   [sepolia],
  //   [
  //     publicProvider()
  //   ]
  // );
  // const { connectors } = getDefaultWallets({
  //   appName: 'Quiz Buzz',
  //   projectId: '8aa71f0b70385f73d228575715cdf6f0',
  //   chains
  // });
  // const wagmiConfig = createConfig({
  //   autoConnect: true,
  //   connectors,
  //   publicClient
  // })
  return (
    <>
      {/* <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains}> */}
      <ToastContainer />
      <BrowserRouter>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        {/* <Routes>
          <Route path="/movie" element={<Moviepage />} />
        </Routes> */}
        <Routes>
          <Route path="/quizez" element={<Quiz />} />
        </Routes>
        <Routes>
          <Route path="/wallet" element={<Walletpage />} />
        </Routes>
        <Routes>
          <Route path="/quizpanel" element={<Quizpanel />} />
        </Routes>
        <Routes>
          <Route path="/leaderboard" element={<LeaderBpage />} />
        </Routes>
        <Routes>
          <Route path="/modalpage" element={<Modalpage />} />
        </Routes>
        <Routes>
          <Route path="/ansermodal" element={<Answermodal />} />
        </Routes>
        <Routes>
          <Route path="/quizpanel2" element={<Quizpanel2 />} />
        </Routes>
        <Routes>
          <Route path="/quizpanel3" element={<Quizpanel3 />} />
        </Routes>
        <Routes>
          <Route path="/ansermodal2" element={<Answermodal2 />} />
        </Routes>
        <Routes>
          <Route path="/ansermodal3" element={<Answermodal3 />} />
        </Routes>
        <Routes>
          <Route path="/modal2" element={<Modal2 />} />
        </Routes>
        <Routes>
          <Route path="/modal3" element={<Modal3 />} />
        </Routes>
        <Routes>
          <Route path="/modal4" element={<Modal4 />} />
        </Routes>
        <Routes>
          <Route path="/firstmodal" element={<Firstmodal />} />
        </Routes>
      </BrowserRouter>
      {/* </RainbowKitProvider>
      </WagmiConfig> */}
    </>
  );
}

export default App;
