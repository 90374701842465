import React from "react";
import "./Quiz.css";
import Quizzes from "../../components/quizzes/quizzes";
import Faq from "../../components/home/faq/faq";
import Footer from "../../components/footer/footer.";
import Bannerquiz from "../../components/Bannerquiz/Bannerquiz";
import Navbar from "../../components/home/Navbar/Navbar";
import Vectorimg from "../../assets/icon/Vector 4.png";

const Quiz = () => {
  return (
    <div>
      <Navbar />
      <Bannerquiz />
      <Quizzes />
      <div className="quizbotom-line">
        <div className="vector-img">
          <img src={Vectorimg} alt="vector-img" />
        </div>
      </div>
      <Faq head="Freaquently Asked Question" />
      <Footer />
    </div>
  );
};

export default Quiz;
