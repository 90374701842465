import React from "react";
import "../../style/main.scss";
import adaptive from "../../assets/images/Our Feature-images/Adaptive.png";
import recommendation from "../../assets/images/Our Feature-images/recommendation.png";
import Titleimg from "../../assets/icon/Group 3928.png";
import imgLine from "../../assets/icon/Rectangle 825.png";
const Features = () => {
  return (
    <div className="featuresParent">
      <div className="feature-img">
        <div className="bannerImg">
          <img src={Titleimg} alt="img" className="w-100" />
        </div>
      </div>
      <div className="feature">
        <div className="heading">
          <h2>
            <span>
              {" "}
              <div className="shadow-div"></div> our
            </span>{" "}
            features
          </h2>
        </div>
        <div className="feature-content ">
          {/* <div className="something"> */}
          <div className="content-1 ">
            <div className="left">
              <div className="border-left"></div>
              <h4>Adaptive Difficulty</h4>
              <p>
                AI algorithms continuously monitor a user's performance and adapt quiz difficulty levels accordingly
              </p>
            </div>
            <div className="right">
              {/* <img src={adaptive} alt="" className="img-100" /> */}
              <video
                autoPlay
                loop
                muted
                playsInline
              // poster="\assets\images\hero\banner.jpg"
              >
                <source src="\asset\vedio\brain.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="content-2">
            <div className="left">
              {/* <img src={recommendation} alt="" className="img-100" /> */}
              <video
                autoPlay
                loop
                muted
                playsInline
              // poster="\assets\images\hero\banner.jpg"
              >
                <source src="\asset\vedio\computer.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="right">
              <div className="border-left"></div>
              <h4>Recommendation System</h4>
              <p>
                Quiz Buzz employs AI-powered recommendation systems that draw from a user's quiz history and preferences.
              </p>
            </div>
          </div>
          <div className="content-1">
            <div className="left">
              <div className="border-left"></div>
              <h4>Chatbot Interface</h4>
              <p>
                An AI-driven chatbot interface guides users through quizzes, transforming the experience into an interactive and engaging journey.
              </p>
            </div>
            <div className="right">
              {/* <img src={adaptive} alt="" className="img-100" /> */}
              <video
                autoPlay
                loop
                muted
                playsInline
              // poster="\assets\images\hero\banner.jpg"
              >
                <source src="\asset\vedio\chatbot.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="content-2">
            <div className="left">
              {/* <img src={recommendation} alt="" className="img-100" /> */}
              <video
                autoPlay
                loop
                muted
                playsInline
              // poster="\assets\images\hero\banner.jpg"
              >
                <source src="\asset\vedio\earning-vedio.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="right">
              <div className="border-left"></div>
              <h4>Earnings</h4>
              <p>
                In Quiz Buzz, knowledge isn't just power—it's earnings. Win rewards, prizes, and more as you challenge your mind and play to win.
              </p>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className="border-b">
        {/* <img src={imgLine} alt="lineImg" /> */}
      </div>
    </div>
  );
};

export default Features;
