import React, { useEffect, useState } from 'react'
import './Answermodal.css'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import MImg from '../assets/icon/Rectangle 54.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Quizimg from '../assets/icon/pancel.png'

const Answermodal = (props) => {
  const [show, setShow] = useState(false)
  const [dataa, setDatA] = useState('')
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    if (location.state && location.state.data) {
      // console.log("dataquizpanel",location.state.data);
      setDatA(location.state.data);
    }
  }, [location.state]);
  const handleButtonClick = () => {
    if (currentQuestionIndex < dataa.length - 1) {
      console.log("answer", dataa);
      setCurrentQuestionIndex(currentQuestionIndex + 1)

    }
  }

  const currentquestion = dataa[currentQuestionIndex]
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        onHide={() => setShow(false)}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
        fullscreen={true}
        size='xl'
        className='modalbox-Main xl'
      >
        <Modal.Body className='modal-Contentanswer'>
          <div className='modal-answer'>
            <div className='modal-page'>
              <span>01/5</span>
            </div>
            <div className='answer-title'>
              <h5>Correct Answer : {currentquestion?.answer}</h5>
            </div>
            <div className='answercontent'>
              <div className='answer-left'>
                <div className='left-question'>
                  <span>{currentquestion?.question}</span>
                </div>
                <div className='details'>
                  <span>Details</span>
                </div>
                <div className='answer'>
                  <p>
                    {currentquestion?.description}
                  </p>
                </div>
                <div className='reward-count'>
                  <p>
                    Reward <span>$10</span>
                  </p>
                </div>
                <div className='next-btn'>
                  <button onClick={handleButtonClick}>Next</button>
                </div>
              </div>
              <div className='answer-right'>
                <div className='vedio'>
                  <div className='img-part'>
                    <img src={currentquestion?.image} alt='img' className='img-fluid' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Answermodal
