import React, { useEffect, useState } from "react";
import Logo from "../../../assets/icon/quizbuzzlogo.png";
import "./Navbar.css";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
// import { useAccount, useBalance } from "wagmi";
// import Web3 from "web3";
// import { useAccount, useBalance } from "wagmi";

// const web3 = new Web3(window.ethereum);
const Navbar = () => {
  const navigate = useNavigate();
  // const { address } = useAccount()

  // const { data, isError, isLoading } = useBalance({
  //   address: address,
  //   chainId: 11155111
  // })
  // console.log(data)



  // if (isLoading) { return ("Fetching balance…") }
  // else { return ("Error fetching balance") }
  // const [walletAddress, setWallet] = useState("");
  // const getBalances = async (address) => {
  //   try {
  //     console.log(address);
  //     const balanceWei = await web3.eth.getBalance(address);
  //     const balanceEther = web3.utils.fromWei(balanceWei, "ether");
  //     return balanceEther;
  //   } catch (error) {
  //     console.error("Error fetching balance:", error);
  //     return "Error fetching balance";
  //   }
  // };

  // useEffect(() => {
  //   // console.log(walletAddress);
  //   if (walletAddress) {
  //     getBalances(walletAddress)
  //       .then((res) => {
  //         console.log(res);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     //   console.log(balaance);
  //   }
  // }, [walletAddress]);

  const handleButtonClick = () => {
    navigate("/wallet");

  };
  return (
    <>
      {/* <div>
        Balance: {data?.formatted} {data?.symbol}
      </div> */}
      <nav className="navbar navbar-expand-lg navbar-main ">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="logo" className="logo" />;
          </Link>
          <button
            className="navbar-toggler toggleButton"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <Icon
                icon="ci:hamburger-lg"
                color="white"
                width="30"
                height="30"
              />
            </span>
          </button>
          <div
            className="collapse navbar-collapse mainMiddle"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav  navUL">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="#">
                  About us
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link " aria-current="page" to="/quizez">
                  Quizzes
                </Link>
              </li>
            </ul>
            <div className="walletButton">
              <form className="d-flex" role="search">
                {/* <ConnectButton /> */}

                <button className="wallet" onClick={handleButtonClick}>
                  Wallet
                </button>
              </form>

            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
