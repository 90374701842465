import React, { useState } from "react";
import "./Quizpanel2.css";
import PenImg from "../../assets/icon/pancel.png";
import { Icon } from "@iconify/react";
import Tick from "../../assets/icon/Group 3896 (1).svg";
import Cross from "../../assets/icon/Group 3895.png";
import Navbar from "../../components/home/Navbar/Navbar";
import { useNavigate } from "react-router-dom";

const Quizpanel2 = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Navigate to the About page
    navigate("/ansermodal2");
  };
  return (
    <>
      <Navbar />
      <div className="quiz-panel">
        <div className="panel-timer">
          <div className="timer-content">
            <p>Time Left</p>
            <span>00:56</span>
          </div>
        </div>
        <div className="question-panel">
          <div className="question-left">
            <h5>Question 01</h5>
            <p>If 5 pencils cost $1.25, how much would 8 pencils cost?</p>
            <div className="quiz-content">
              <div className="quiz-lefside">
                <div className="a">
                  <span>
                    {" "}
                    A <button>$3.00</button>{" "}
                  </span>
                  <img src={Tick} alt="test" />
                </div>
                <div className="a">
                  <span>
                    {" "}
                    C<button>$2.00</button>{" "}
                  </span>
                  <img src={Cross} alt="cross" />
                </div>
              </div>
              <div className="quiz-lefside">
                <div className="a">
                  <span>
                    {" "}
                    B <button>$1.50</button>{" "}
                  </span>
                </div>
                <div className="a">
                  <span>
                    {" "}
                    D <button>$1.60</button>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="question-right">
            <div className="quiz-img">
              {/* <img src={props.img} alt="pen-img" className="pen-img" /> */}
              {/* <div className="quizImg">
              <img src={PenImg} alt="pen-img" className="img-fluid" />
            </div>{" "} */}
              <video
                autoPlay
                loop
                muted
                playsInline
                poster="\assets\images\hero\banner.jpg"
                className="vediot"
              >
                <source
                  src="\asset\vedio\Vikings - Ragnar To Rollo_ _When Everyone Wanted You Dead!_ [Official Vikings Scene] [HD].mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
        <div className="quizpanel-last">
          <div className="quizBottom-left">
            <button>$10</button>
          </div>
          <div className="quizBottom-middle">
            <div className="quizBottom-middle">
              <button onClick={handleButtonClick}>Submit</button>
            </div>
          </div>
          <div className="quizBottom-right">
            <span>02 / 10</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quizpanel2;
