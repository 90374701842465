import React from "react";
import "./footer.css";
import Logo from "../../assets/icon/quizbuzzlogo.png";
import { Icon } from "@iconify/react";
import Eclipse from "../../assets/icon/Ellipse 1.png";
import Shadow from "../../assets/icon/3769.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <div className="footer">
        <div className="bg-img">
          <div className="footer-content">
            <div className="shadow">
              <div className="footer-a">
                <div className="content-first">
                  <div className="content-logo">
                    <img src={Logo} alt="logo" className="footer-logo" />
                  </div>
                  <div className="content-paragraph">
                    Lorem ipsum dolor sit amet, <br /> consectetur adipiscing
                    elit. Etiam eu <br />
                    turpis molestie, dictum est a, mattis <br /> tellus. Sed
                    dignissim, metus nec <br /> fringilla accumsan, risus sem
                    sole
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-b">
              {" "}
              <div className="content-title">
                <a className="title-about">About</a>
              </div>
              <div className="title-subheading">
                <a>Product</a>
              </div>
              <div className="title-subheading">
                <a>Resource</a>
              </div>
              <div className="title-subheading">
                <a>Term & Condition</a>
              </div>
              <div className="title-subheading">
                <a>FAQ</a>
              </div>
            </div>
            <div className="footer-b">
              <div className="content-title">
                <a className="title-about">Company</a>
              </div>
              <div className="title-subheading">
                <a>Our Team</a>
              </div>
              <div className="title-subheading">
                <a>Partner With Us</a>
              </div>
              <div className="title-subheading">
                <a>Privacy & Policy</a>
              </div>
              <div className="title-subheading">
                <a>Features</a>
              </div>
            </div>
            <div className="footer-d">
              <div className="content-title">
                <a className="title-about">Contact</a>
              </div>
              <div className="title-subheading">
                <a>+012 3456789</a>
              </div>
              <div className="title-subheading">
                <a>asdaf@gmail.com</a>
              </div>
              <div className="social-icons">
                <div className="social-icon">
                  <Icon icon="mdi:youtube" width="25" height="25" />
                  <Icon icon="fontisto:discord" width="25" height="25" />
                  <Icon icon="mdi:instagram" width="25" height="25" />
                </div>
                <div className="social-icon">
                  <Icon icon="jam:facebook" width="25" height="25" />
                  <Icon icon="mdi:twitter" width="25" height="25" />
                  <Icon icon="fa:youtube" width="18" height="22" />
                </div>
              </div>
          
            </div>
          </div>
          <div className="copyright">
            <span>© Copyright</span>
          </div>
        </div>
      </div> */}
      <div className="footer">
        <img src={Shadow} alt="shadow" className="shadow" />
        <div className="footerContent-a">
          <div className="first-content">
            <div className="logo">
              <img src={Logo} alt="logo" className="sha" />
            </div>

            <p className="p">
              Quiz Buzz is an AI-powered quizzing funhouse, where knowledge meets entertainment. Engage in diverse, AI-generated quizzes, win rewards, and fuel your curiosity, all while having a blast. Join us in the future of quizzing!
            </p>
          </div>
        </div>
        <div className="footerContent-a">
          <h6>About</h6>
          <a href="#">Product</a>
          <a href="#">Resource</a>
          <a href="#">Term & Condition</a>
          <a href="#">FAQ</a>
        </div>
        <div className="footerContent-a">
          <h6>Company</h6>
          <a href="#">Our Team</a>
          <a href="#">Partner With Us</a>
          <a href="#">Features</a>
        </div>
        <div className="footerContent-a">
          <h6>About</h6>
          <a href="#">+012 3456789</a>
          <a href="#">asdaf@gmail.com</a>
          <div className="social-icons">
            <div className="social-icon">
              <Link
                to={"https://www.youtube.com/channel/UCruf4HxrixWGt-DFBzsUZTg"}
                className="socialIcon"
              >
                <Icon icon="mdi:youtube" width="25" height="25" />
              </Link>{" "}
              <Link to={"https://t.co/eaVPdOWJpX"} className="socialIcon">
                <Icon icon="fontisto:discord" width="25" height="25" />
              </Link>{" "}
              <Link
                to={"https://www.instagram.com/quizbuzz.ai/"}
                className="socialIcon"
              >
                <Icon icon="mdi:instagram" width="25" height="25" />
              </Link>{" "}
            </div>
            <div className="social-icon">
              <Link
                to="https://www.facebook.com/quizbuzzai"
                className="socialIcon"
              >
                <Icon icon="jam:facebook" width="25" height="25" />
              </Link>{" "}
              <Link
                to={"https://twitter.com/Quizbuzz_ai"}
                className="socialIcon"
              >
                <Icon icon="mdi:twitter" width="25" height="25" />
              </Link>{" "}
              <Link
                to="https://www.tiktok.com/@quizbuzz.ai"
                className="socialIcon"
              >
                <Icon icon="iconoir:tiktok" width="22" height="22" />
              </Link>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        <span>© Copyright</span>
      </div>
    </>
  );
};

export default Footer;
