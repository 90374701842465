/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./Quizpanel2.css";
import PenImg from "../../assets/icon/pancel.png";
import { Icon } from "@iconify/react";
import Tick from "../../assets/icon/Group 3896 (1).svg";
import Cross from "../../assets/icon/Group 3895.png";
import Navbar from "../../components/home/Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from 'react-bootstrap/Modal'
import "./Answermodal.css"
import Countdown from 'react-countdown';


const Quizpanel = (props) => {
  const [show, setShow] = useState(false);
  const [dataa, setDatA] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [remainingtime, setRemainingtime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [correctFlag, setCorrectFlag] = useState(false)
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [userScore, setUserScore] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [timerActive, setTimerActive] = useState(true);
  const [showAnswer, setShowAnswer] = useState(false);
  const [lockAnswer, setLockAnswer] = useState(false);

  const [score, setScore] = useState(0);
  const navigate = useNavigate();
  const location = useLocation()
  const { data } = location.state
  const intervalRef = useRef(null);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (data) {
      setDatA(data);
      console.log("data", data);
      setTimer(Date.now() + 50000)
    }
  }, [data]);
  useEffect(() => {
    console.log(timer);
  }, [timer]);

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {

    setCurrentTime(minutes * 60 + seconds)

    if (completed) {
      setShow(true)
      setTimerActive(false);
    } else {
      // Render a countdown
      return <span>{hours}:{minutes}:{seconds}</span>;
    }
  };
  // =====================================================================================
  const handleButtonClick = () => {
    setShow(true);
    const shuffledOptions = shuffleArray(data[currentQuestionIndex].options);
    console.log("shuffle", shuffledOptions);
    if (currentQuestionIndex == dataa.length - 1) {
      setQuizCompleted(true)
      setTimerActive(false);
    }
    setRemainingtime(currentTime)
    setShowAnswer(true);
    setLockAnswer(false);
  };

  useEffect(() => {
    console.log(remainingtime);
  }, [remainingtime])

  const handleNextButton = () => {
    if (!quizCompleted) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTimerActive(true);
      setShowAnswer(false);
      setLockAnswer(false);
    }
    setSelectedOption(null);
    setShow(false);
    console.log(Date.now() + ((remainingtime * 1000) + 10000));
    setTimer(Date.now() + ((remainingtime * 1000) + 10000));

  }

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleOptionSelect = (optionIndex, Currentoption, correctAns) => {
    console.log("Selected option index:", optionIndex);
    console.log("Current option:", Currentoption);
    console.log("Correct answer:", correctAns);
    // setShowAnswer(false)
    setSelectedOption(optionIndex);
    const isCorrect = Currentoption === correctAns;
    setCorrectFlag(isCorrect);

    if (isCorrect) {
      setCorrectCount(correctCount + 1);
      setScore(score + 1);
    } else {
      setIncorrectCount(incorrectCount + 1);
    }
  };


  return (
    <>
      <Navbar />
      <div className="quiz-panel">
        <div className="panel-timer">
          <div className="timer-content">
            <p>Time Left</p>
            {/* {
              timerActive && timer > 0 ? <Countdown
                date={timer}
                renderer={renderer}
              /> : <></>
            } */}

          </div>
        </div>
        <div className="question-panel">
          <div className="question-left">
            <div className="quest-main">
              <h5>Question {currentQuestionIndex + 1}</h5>
              <p>{dataa[currentQuestionIndex]?.question}</p>

            </div>
            <div className="quiz-content">
              <div className="quiz-lefside">
                <div className="a">

                  <div className="parent">
                    {
                      data[currentQuestionIndex].options.map((item, optionIndex) => {
                        const splitedOption = item.split(/[.(]/)
                        const isCorrect = item === dataa[currentQuestionIndex]?.answer;
                        const isSelected = selectedOption === optionIndex;
                        // console.log(splitedOption);

                        return (
                          <div
                            key={optionIndex}>
                            <button
                              disabled={lockAnswer}
                              className={`option ${showAnswer && isCorrect ? 'correct' : ''} 
                              ${showAnswer && isSelected && !isCorrect ? 'incorrect' : ''}`}
                              style={{ backgroundColor: isSelected ? '#ca0587' : 'transparent' }}
                              onClick={() => handleOptionSelect(optionIndex, item, dataa[currentQuestionIndex].answer)}
                            >
                              <div className="icon-container">
                                {showAnswer && isCorrect && (
                                  <img className="icon-green" src={Tick} alt="Tick" />
                                )}
                                {showAnswer && !isCorrect && (
                                  <img className="icon-red" src={Cross} alt="Cross" />
                                )}
                              </div>
                              <div className="child">
                                {splitedOption.length === 2 ? splitedOption[1] : item}
                              </div>

                            </button>
                          </div>

                          // <div >
                          //   <span key={optionIndex} >
                          //     <span style={{ marginLeft: "20px" }}></span>
                          //     {String.fromCharCode(65 + optionIndex)}
                          //     <span style={{ marginLeft: "-10px" }}></span>
                          //     <button
                          //       disabled={false}
                          //       onClick={() =>
                          //         handleOptionSelect(
                          //           optionIndex,
                          //           splitedOption.length == 2 ? splitedOption[1] : item,
                          //           dataa[currentQuestionIndex].answer
                          //         )}
                          //       style={{
                          //         backgroundColor:
                          //           optionIndex === selectedOption ? '#ca0587' : 'transparent',
                          //       }}
                          //     // className={`option ${selectedOption === optionIndex ? 'selected' : ''} 
                          //     // ${correctFlag && currentQuestionIndex.answer === item ? 'correct' : ''} 
                          //     // ${correctFlag && !currentQuestionIndex.answer === item ? 'incorrect' : ''}`}
                          //     >
                          //       <div className="child">
                          //         {splitedOption.length == 2 ? splitedOption[1] : item}
                          //       </div>
                          //       {/* {correctFlag && currentQuestionIndex.answer === item ? (
                          //         <img className="icon-green" src={Tick} alt="Tick" />
                          //       ) : null
                          //       }

                          //       {
                          //         correctFlag && !currentQuestionIndex.answer === item ? (
                          //           <img className="icon-red" src={Cross} alt="Cross" />
                          //         ) : null
                          //       } */}
                          //     </button>
                          //     {/* <label htmlFor={item} className={selectedOption == item ? "active" : ""}>
                          //       {item}
                          //       <input name="qasim" id={item} type="radio" onClick={(e) => {
                          //         console.log(e.target.id)
                          //         setSelectedOption(e.target.id)

                          //       }} />
                          //     </label> */}
                          //   </span>

                          // </div>

                        )
                      }
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Image */}
          <div className="question-right">
            <div className="quiz-img">
              <img src={dataa[currentQuestionIndex]?.image} alt="pen-img" className="pen-img" />
            </div>
          </div>
        </div>
        <div className="quizpanel-last">
          <div className="quizBottom-left">
            <button>$10</button>
          </div>
          <button onClick={() => {
            setShowAnswer(true)
            setLockAnswer(true)

          }
          }
            // <div className="answer-button" {showAnswer && isSelected:items{
            // disabled = { lockAnswer }
            // }}>
            // Show Answer
            // </div>
            disabled={lockAnswer}
            className="answer-button">
            Show Answer
          </button>
          <div className="quizBottom-middle">
            <button
              onClick={handleButtonClick}
              className="next-button"
              disabled={selectedOption === null}

            >
              Submit
            </button>
          </div>
          <div className="quizBottom-right">
            <span>{`${currentQuestionIndex + 1} / ${dataa.length}`}</span>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
        fullscreen={true}
        size='xl'
        className='modalbox-Main xl'
      >
        <Modal.Body className='modal-Contentanswer'>
          {quizCompleted ? (
            <div className='quiz-result'>
              <h2>Quiz Result</h2>
              <p>Your score: {score} / {dataa.length}</p>
              <p>Total Questions: <span id='ans'>{dataa.length}</span></p>
              <p>Correct Answers: <span id='ans'>{correctCount}</span></p>
              <p>Wrong Answers: <span id='ans'>{incorrectCount}</span></p>
              {/* <p>Percentage: <span id='ans'>{calculatePercentage().toFixed(2)}%</span></p> */}
              {/* <button onClick={handleNextButton}>Close</button> */}
            </div>
          ) : (
            <>
              <div className='modal-answer'>
                <div className='modal-page'>
                  <span>{`${currentQuestionIndex + 1} / ${dataa.length}`}</span>
                </div>
                <div className='answer-title'>
                  <h5>Correct Answer : {dataa[currentQuestionIndex]?.answer}</h5>
                </div>
                <div className='answercontent'>
                  <div className='answer-left'>
                    <div className='left-question'>
                      <h4>Question {currentQuestionIndex + 1}</h4>
                      <p>{dataa[currentQuestionIndex]?.question}</p>
                    </div>
                    <div className='details'>
                      <span>Details</span>
                    </div>
                    <div className='answer'>
                      <p>
                        {dataa[currentQuestionIndex]?.description}
                      </p>
                    </div>
                    <div className='reward-count'>
                      <p>
                        Reward <span>$10</span>
                      </p>
                    </div>
                    <div className='next-btn'>
                      <button onClick={handleNextButton}>Next</button>
                    </div>
                  </div>
                  <div className='answer-right'>
                    <div className='vedio'>
                      <div className='img-part'>
                        <img src={dataa[currentQuestionIndex]?.image} alt='img' className='img-fluid' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Quizpanel;