import React from "react";
import "./Bannerquiz.css";
import LftImg from "../../assets/icon/Rectangle 50.png";
import MiddleImg from "../../assets/icon/image 29.png";
import LstImg from "../../assets/icon/Rectangle 46.png";
import Blogo from "../../assets/icon/Group 3929.png";
const Bannerquiz = () => {
  return (
    <>
      {/* <div className="quiz-banner">
        <div className="banner-left">
          <div className="left-img">
            <div className="image-container">
              <img src={Blogo} alt="Blogo" className="image-logo" />
              <img src={LftImg} alt="img" className="image-content" />
            </div>
          </div>
        </div>
        <div className="banner-middle">
          <div className="Middle-img">
            <div className="image-container">
              <img src={Blogo} alt="Blogo" className="image-logo" />
              <img src={MiddleImg} alt="img" className="image-content" />
              <p>The Ultimate "Hannah Montana" Trivia Quiz</p>
            </div>
          </div>
        </div>
        <div className="banner-right">
          <div className="last-img">
            <div className="image-container">
              <img src={Blogo} alt="Blogo" className="image-logo" />
              <img src={LstImg} alt="img" className="image-content" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="banner-quiz">
        <div className="banner-left">
          <div className="leftImg">
            <img src={Blogo} alt="Blogo" className="image-logo " />
            <img src={LftImg} alt="leftImg" className="leftimgs img-fluid" />
          </div>
        </div>
        <div className="banner-middle">
          <div className="middleImg">
            <img src={Blogo} alt="Blogo" className="image-logo" />
            <img
              src={MiddleImg}
              alt="middleImg"
              className="middleImgs img-fluid"
            />
            <p>The Ultimate "Hannah Montana" Trivia Quiz</p>
          </div>
        </div>
        <div className="banner-right">
          <div className="rightImg">
            <img src={Blogo} alt="Blogo" className="image-logo" />
            <img src={LstImg} alt="leftImg" className="rightimgs img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Bannerquiz;
