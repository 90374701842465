import React, { useState } from "react";
import "./Answermodal3.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import MImg from "../assets/icon/Rectangle 54.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Answermodal3 = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Navigate to the About page
    navigate("/quizez");
  };
  return (
    <>
      {/* <Button variant="primary" onClick={() => setShow(true)}>
Custom Width Modal
</Button>{" "} */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen={true}
        size="xl"
        backdrop="static"
        // centered
        className="modalbox-Main xl"
      >
        {/* <Modal.Header closeButton className="modal-Content">
  <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
</Modal.Header> */}
        <Modal.Body className="modal-Contentanswer">
          {/* <div className="quizModal-section">
    <div className="qmodal-left">
      <div className="modalImg">
        <img src={MImg} alt="MImg" className="modalimg" />
      </div>
    </div>
    <div className="qmodal-right">
      <div className="quiz-section">
        <div className="question">
          <h6>Question</h6>
          <div className="paragraph">
            <p>What's the first thing Hannah says on tape one?</p>
          </div>{" "}
        </div>
        <div className="modalReward">
          <p>
            Reward<span>$10</span>
          </p>
        </div>
        <div className="quiz-start">
          <div className="start-div">
            <p>Do You Want To Start The Quiz ? Tap On The Button!</p>
          </div>
          <button>Let’s Start</button>
        </div>
      </div>
    </div>
  </div> */}
          <div className="modal-answer">
            <div className="modal-page">
              <span>01/5</span>
            </div>
            <div className="answer-title">
              <h5>Your Answer Is Correct</h5>
            </div>
            <div className="answercontent">
              <div className="answer-left">
                <div className="left-question">
                  <span>
                    If 5 pencils cost $1.25, how much would 8 pencils cost?
                  </span>
                  <br />
                  <span>From where it is taken?</span>
                </div>
                <div className="details">
                  <span>Details</span>
                </div>
                <div className="answer">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Nunc vulputate libero et
                    velit.Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit. Nunc vulputate libero et
                    velit.ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
                <div className="reward-count">
                  <p>
                    Reward <span>$10</span>
                  </p>
                </div>
                <div className="next-btn">
                  <button onClick={handleButtonClick}>Avail Reward</button>
                </div>
              </div>
              <div className="answer-right">
                <div className="vedio">
                  <div className="img-part">
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      poster="\assets\images\hero\banner.jpg"
                      className="vedio-css"
                    >
                      <source
                        src="\asset\vedio\Vikings - Ragnar To Rollo_ _When Everyone Wanted You Dead!_ [Official Vikings Scene] [HD].mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Answermodal3;
