import React from "react";
import Mod from "../../Modal/Modal";
import { Link } from "react-router-dom";

const Modalpage = () => {
  return (
    <>
      {" "}
      <Mod />
    </>
  );
};

export default Modalpage;
