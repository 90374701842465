import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Icon } from "@iconify/react";
import "./faq.css";
import Send from "../../../assets/icon/Send Icon.png";

const faq = (props) => {
  return (
    <>
      <div className="accordian-section">
        <div className="accordian-content">
          <div className="accordian-title">
            <span className="">{props.head}</span> <br />
            <span className="question">Wanna Ask Something ?</span>
          </div>
          <div className="accordianMain">
            <div className="accordian-left">
              <div className="accordion rounded-0" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button custom-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      What is Quiz Buzz all about ? <span>+</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <span>Quiz Buzz is an exciting platform that offers a wide range of quizzes on various topics. It's a fun and engaging way to test your knowledge, learn new things, and earn rewards while having a great time.</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed custom-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How do I participate in quizzes on Quiz Buzz ? <span>+</span>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <span>It's simple! Just sign up on our platform, browse through our selection of quizzes, and pick the one that interests you. Click on it, and you're ready to start quizzing!</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed custom-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What types of quizzes can I find on Quiz Buzz ? <span>+</span>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <span>We offer quizzes on a diverse range of topics, from general knowledge and pop culture to history, science, and more. There's something for everyone!</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfour">
                    <button
                      className="accordion-button collapsed custom-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      Is Quiz Buzz suitable for all ages ? <span>+</span>
                    </button>
                  </h2>
                  <div
                    id="collapsefour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingfour"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <span>Absolutely! We have quizzes tailored for different age groups and difficulty levels. Whether you're a student, a trivia enthusiast, or just looking to have some fun, there's a quiz for you.</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfive">
                    <button
                      className="accordion-button collapsed custom-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefive"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      Is my data safe on Quiz Buzz ? <span>+</span>
                    </button>
                  </h2>
                  <div
                    id="collapsefive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingfive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <span>Yes, we take your privacy seriously. Quiz Buzz follows stringent security measures to ensure your data remains safe and protected.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordian-right">
              <div className="accordion" id="accordionExample2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsix">
                    <button
                      className="accordion-button custom-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesix"
                      aria-expanded="true"
                      aria-controls="collapsesix"
                    >
                      Are there prizes or rewards for participating in quizzes ? <span>+</span>
                    </button>
                  </h2>
                  <div
                    id="collapsesix"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingsix"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <span>Yes, indeed! At Quiz Buzz, we believe in rewarding your knowledge and efforts. You can win exciting prizes, tokens, and even cash rewards, depending on the quiz and your performance.</span>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                    <button
                      className="accordion-button collapsed custom-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseseven"
                      aria-expanded="false"
                      aria-controls="collapseseven"
                    >
                      Is Quiz Buzz free to use ? <span>+</span>
                    </button>
                  </h2>
                  <div
                    id="collapseseven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingseven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <span>Yes, Quiz Buzz is free to join and play. You can start quizzing and enjoying the platform without any cost in demo mode.</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingeight">
                    <button
                      className="accordion-button collapsed custom-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseeight"
                      aria-expanded="false"
                      aria-controls="collapseeight"
                    >
                      How often are new quizzes added to Quiz Buzz ?
                      <span>+</span>
                    </button>
                  </h2>
                  <div
                    id="collapseeight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingeight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <span>At Quiz Buzz, our AI-powered quiz generator constantly churns out fresh quizzes. New quizzes are added daily, ensuring there's always something exciting to discover and test your knowledge on. Keep coming back for your daily dose of quiz fun!</span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingnine">
                    <button
                      className="accordion-button collapsed custom-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenine"
                      aria-expanded="false"
                      aria-controls="collapsenine"
                    >
                      How many modes are there in Quiz Buzz ? <span>+</span>
                    </button>
                  </h2>
                  <div
                    id="collapsenine"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnine"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <span>In Quiz Buzz, we offer two exciting modes i.e. Demo Mode and Live Mode to enhance your quiz experience:</span>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingten">
                    <button
                      className="accordion-button collapsed custom-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseten"
                      aria-expanded="false"
                      aria-controls="collapseten"
                    >
                      How can I invite friends to Quiz Buzz ? <span>+</span>
                    </button>
                  </h2>
                  <div
                    id="collapseten"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingten"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <span>Sharing the quiz fun with friends is easy! You can invite them to join Quiz Buzz or share your quiz achievements on social media.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="newsLetter">
            <div className="newsLetter-content">
              <label htmlFor="" className="newsletter-title">
                Newsletter
              </label>
              <div className="input-container">
                <input
                  type="email"
                  placeholder="Your email"
                  className="newsLetter-email"
                  maxLength="40"
                />
                <img src={Send} alt="send" className="send-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default faq;
