import React from "react";
import Card from "../card";
import Sportcard from "./Sportcard";
import { useNavigate } from "react-router-dom";

const SportsQuiz = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    console.log("dd");
    navigate("/quizez");
  };
  return (
    <div>
      <div className="quiz-grid">
        {/* <Card />
        <Card /> */}
        <Sportcard title={"Football"} image={"../../../asset/images/Football bg.png"} />
        <Sportcard title={"Cricket"} image={"../../../asset/images/Cricket bg.png"} />
        {/* <Sportcard />
        <Sportcard /> */}
      </div>
      <div className="button-show">
        {/* <button className="showmore-btn" onClick={handleButtonClick}>
          Show mores
        </button> */}
        {/* <button onClick={() => console.log("hi")}> test </button> */}
      </div>
    </div>
  );
};

export default SportsQuiz;
