import React from "react";
import "../../style/main.scss";
import Button from "../button";
import visionVideo from "../../assets/videos/Homepage-videos/vision-video.mp4";
import imgLine from "../../assets/icon/Rectangle 825.png";
const Vision = () => {
  const explore = () => { };

  return (
    <div>
      <div className="vision">
        <div className="top text-black v-between">
          <div className=" v-center">
            <h3 className="montserrat fw-bold me-1">
              37K+
              <span className="fw-500 ms-2">Users</span>
            </h3>
          </div>

          <div className=" v-center">
            <h3 className="montserrat fw-bold ">
              100%
              <span className="fw-500 ms-2">Success rate </span>
            </h3>
          </div>
          <div className=" v-center">
            <h3 className="montserrat fw-bold me-2">
              100%
              <span className="fw-500 ms-2">Enrolled </span>
            </h3>
          </div>
        </div>
        <div className="bottom v-center flex-end">
          <div className="left">
            <video
              autoPlay
              loop
              muted
              playsInline
              id="vision-video"
              className="img-fluid"
            >
              <source src={visionVideo} type="video/mp4" />
            </video>
          </div>
          <div className="right flex-end">
            <div className="heading">
              <h1 className="text-ends">Our</h1>
              <h1 className="text-end">vision</h1>
            </div>

            <p className="para  ">
              Our aim is to provide personalized, engaging, and secure quiz experiences that cater to diverse interests, fostering learning, entertainment, and community building while offering tangible rewards
            </p>
            <div className="vision-btn">
              <Button
                text="Explore"
                width="92px"
                height="28px"
                className="btn-prime btn-primary mt-3 me-2 explore"
                onClick={explore}
              />
            </div>
          </div>
        </div>
        <div className="bottomLine">
          <div className="endLine">
            <img src={imgLine} alt="lineImg" className="Bimage" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
