import React from "react";
import "../../style/main.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "../card";
// import NewQuiz from "./newQuiz";
import Button from "../button";
import EntertainmentQuiz from "./entertainmentQuiz";
import SportsQuiz from "./sportsQuiz";
// import ESportQuiz from "./eSportQuiz";
import Vectorimg from "../../assets/icon/Vector 4.png";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Quizzes = () => {
  const navigate = useNavigate();
  const [currentLocation, setCurrentLocation] = useState();
  const showMore = () => { };
  const location = useLocation();
  useEffect(() => {
    console.log("hsah");
    setCurrentLocation(location.pathname);
  }, [location]);
  const handleButtonClick = () => {
    navigate("/quizez");
  };

  return (
    <div>
      <div className="quizzes-content">
        <div className="quizzes">
          <div className="heading ">
            <h1 className="v-center h-center">Quizzes</h1>
          </div>
          <div className="tabs v-center h-center c-direction">
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-5"
            >
              {/* <Tab eventKey="home" title="New">
                <NewQuiz />
              </Tab> */}
              <Tab eventKey="profile" title="Movies">
                <EntertainmentQuiz />
              </Tab>
              <Tab eventKey="contact" title="Sports">
                <SportsQuiz />
              </Tab>
              {/* <Tab eventKey="esport" title="Esport">
                <ESportQuiz />
              </Tab> */}
            </Tabs>
          </div>
          <div className="quizzes-btn v-center h-center mt-5">
            {/* <Button
              text="Show more"
              width="156px"
              height="44px"
              className="btn-prime btn-primary "
              onClick={showMore}
            /> */}
            {currentLocation == "/quizez" ? (
              <div className="button-show">
                <button
                  className="showmore-btn"
                  onClick={() => console.log("Load more button clicked")}
                >
                  Load more
                </button>
              </div>
            ) : (
              <div className="button-show">
                <button className="showmore-btn" onClick={handleButtonClick}>
                  Show more
                </button>
              </div>
            )}
          </div>
        </div>
        {/* <div className="quizbotom-line">
          <div className="vector-img">
            <img src={Vectorimg} alt="vector-img" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Quizzes;
