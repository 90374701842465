import React, { useState, useEffect } from "react";
import "../../style/main.scss";
import bannerAnim from "../../../src/assets/videos/Homepage-videos/Banner-anim.mp4";
import Button from "../button";

const Banner = () => {
  const connectWallet = () => { };
  const words = ["Explore", "Engage", "Excel"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Change word every 2 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, []);
  // console.log(words[currentWordIndex]);
  return (
    <div>
      <div className="banner">
        <div className="left">
          <div className="heading">
            <h3 className="first">Welcome to</h3>
            <div className="animation-text text-animation">
              <div className="d-flex">
                <h3>quizz buzz! </h3>{" "}
                <h3 className="animated-word">
                  <span> {words[currentWordIndex]}</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="para mt-4">
            <p>
              Dive into a world of knowledge, fun, and rewards. Challenge yourself with mind-bending quizzes, unlock exciting discoveries, and turn your expertise into prizes. Join us on an adventure where every answer counts. It's time to buzz with excitement and fuel your curiosity!
            </p>
          </div>
          <div className="mt-5 pt-2 banner-btn">
            <Button
              text="Connect to wallet"
              width="223px"
              height="44px"
              className="btn-prime btn-primary me-4"
              onClick={connectWallet}
            />
            <Button
              text="Add Tokens"
              width="159px"
              height="44px"
              className="btn-prime btn-secondary"
              onClick={connectWallet}
            />
          </div>
        </div>

        <div className="right ">
          <video autoPlay loop muted playsInline id="myvid">
            <source src={bannerAnim} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Banner;
